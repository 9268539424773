import awsconfig from "./aws-exports";
import { Auth } from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";

Amplify.configure(awsconfig);
class AuthService {
  signIn(username, password) {
    return Auth.signIn(username, password);
  }

  async signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  getCurrentSession() {
    return Auth.currentSession();
  }

  getCurrentUserInfo() {
    return Auth.currentUserInfo();
  }

  async authHeader() {
    try {
      const currentSession = await Auth.currentSession();
      return {
        Authorization: `Bearer ${currentSession?.idToken?.jwtToken}`,
      };
    } catch (e) {
      // Ignore error
    }
    // If here, return empty header
    return {};
  }
}

export default new AuthService();
