import { useCallback, useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Editor from "@monaco-editor/react";
import "./ModalStyles.css";
import { ServiceDefinitionsDataService } from "./services/tasks.service";
import { alert } from "react-bootstrap-confirmation";

const styles = {
  dialogButtons: {
    width: "6em",
  },
};

const NewServiceExecutionModal = (props) => {
  const [newServiceExecution, setNewServiceExecution] = useState({ description: "", config: "" });
  const [baseTemplate, setBaseTemplate] = useState(null);
  const [variables, setVariables] = useState([]);
  const [varValues, setVarValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const configRef = useRef(null);
  const { svcDefId } = props;
  let isConfigValid = false;
  try {
    // Try parsing config. If it has errors, it's gonna raise exception
    JSON.parse(newServiceExecution.config);
    isConfigValid = true;
  } catch (_) {
    // If here, it means config is not a valid JSON
  }

  const onCancel = () => {
    props?.onCancel?.();
  };

  const onOk = useCallback(
    (event) => {
      // If the configuration is not a valid JSON yet, ask for user confirmation
      if (!isConfigValid) {
        alert(
          "The configuration is not a valid JSON and can cause problems for the service to start. Please correct it to start the service.",
          {
            title: "Invalid Configuration",
            okText: "Close",
            okButtonStyle: "secondary",
          }
        );
      } else {
        // If here we can assume we have a valid JSON in the config.
        const parsedConfig = JSON.parse(newServiceExecution.config);
        props?.onOk?.({ ...newServiceExecution, config: { ...parsedConfig } });
      }
    },
    [newServiceExecution, props, isConfigValid]
  );

  useEffect(() => {
    setIsLoading(true);
    // Load the service def
    new ServiceDefinitionsDataService()
      .fetch(svcDefId)
      .then((res) => {
        const tmp = res?.data?.template;
        if (tmp) {
          // Determine variables
          const allVariables = tmp.match(/__VARIABLE.*?__/g) ?? [];
          setVariables(allVariables.map((v) => [v, v.replace(/^__VARIABLE_/, "").replace(/__$/, "")]));
          setBaseTemplate(tmp);
          // Set initial value of config
          setNewServiceExecution((se) => ({ ...se, serviceDefId: res.data.sk, type: res.data.type, config: tmp }));
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
      });
  }, [svcDefId]);

  const onVariableInputChange = useCallback(
    (event) => {
      // Which field is edited
      const fieldName = event?.target?.name;
      if (fieldName) {
        // register new var value
        const newVarValues = { ...varValues, [fieldName]: event.target.value };
        setVarValues(newVarValues);
        setNewServiceExecution((se) => {
          se.config = baseTemplate;
          Object.keys(newVarValues).forEach((v) => {
            se.config = se.config.replace(new RegExp(`__VARIABLE_${v}__`, "g"), newVarValues[v]);
          });
          return { ...se };
        });
      }
    },
    [varValues, baseTemplate]
  );

  return (
    <Modal show={true} onHide={onCancel} size="xl" centered animation={true}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex flex-row">Start New Service</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: "70vh",
          minHeight: "60vh",
          overflowY: "auto",
        }}
      >
        <Form.Group className="formrow">
          <Form.Label className="formlabel">Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Description"
            value={newServiceExecution.description}
            onChange={(e) => setNewServiceExecution((te) => ({ ...te, description: e.target.value }))}
            disabled={isLoading}
          />
          <Form.Control.Feedback type="invalid">Please enter a service description.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="formrow align-items-start">
          <Form.Label className="formlabel">Variables</Form.Label>
          <div
            className="d-flex flex-column border w-100 bg-light"
            style={{ borderRadius: "0.25rem", padding: ".375rem .75rem", minHeight: "2rem", opacity: variables?.length ? "1" : "0.3" }}
          >
            {isLoading && (
              <div>
                Loading... <Spinner animation="border" variant="secondary" size="sm" />
              </div>
            )}
            {!isLoading &&
              variables.map((v, i) => (
                <div className="d-flex flex-row align-items-center mb-2" key={v}>
                  <div className="col-2 text-capitalize">{v[1].replace(/_/g, " ").toLowerCase()}</div>
                  <Form.Control
                    type="text"
                    placeholder={`${v[1]} Value`}
                    onChange={onVariableInputChange}
                    name={v[1]}
                    value={varValues[i]}
                  />
                </div>
              ))}
          </div>
        </Form.Group>
        <Form.Group className="formrow align-items-start">
          <Form.Label className="formlabel">
            <div className="d-flex flex-column ">
              <div>Configuration</div>
              {!isConfigValid && (
                <div className="text-danger" style={{ fontSize: "0.5rem" }}>
                  [Configuration is not a valid JSON!]
                </div>
              )}
            </div>
          </Form.Label>
          <div className="d-flex p-0 col mb-3" style={{ height: "20em" }}>
            <Editor
              language="json"
              className="form-control form-control-sm p-0"
              options={{
                scrollBeyondLastLine: false,
                minimap: { enabled: false },
                lineNumbers: false,
                glyphMargin: false,
                lineDecorationsWidth: 0,
                renderLineHighlight: "none",
              }}
              value={newServiceExecution.config}
              onChange={() => {
                configRef?.current?.setValue(newServiceExecution.config);
              }}
              onMount={(ed) => {
                configRef.current = ed;
              }}
            />
          </div>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} variant="secondary" style={styles.dialogButtons}>
          Close
        </Button>
        <Button onClick={onOk} variant="primary" style={styles.dialogButtons}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewServiceExecutionModal;
