import { useCallback, useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ModalStyles.css";
import { ScenarioDefinitionsDataService, ServiceExecutionsDataService } from "./services/tasks.service";
import { EXECUTIONSTATECOLORMAPS, AUTO_REFRESH_PERIOD_MS, ACTIVE_EXECUTION_STATES, KEY_PREFIXES } from "./constants";
import Badge from "react-bootstrap/Badge";
import { formatDate, compareDates, truncateStringWithEllipsis } from "./common-utils";
import { MdRefresh } from "react-icons/md";
import Switch from "react-switch";
import { FaHandPaper as StopIcon } from "react-icons/fa";
import ScenarioExecutionDetailsModal from "./ScenarioExecutionDetailsModal";
import ReactJson from "react-json-view";
import { alert, confirm } from "react-bootstrap-confirmation";

const getStatusBadge = (status) => {
  return (
    <Badge pill bg={EXECUTIONSTATECOLORMAPS[status] ?? "secondary"} style={{ width: "8rem" }}>
      {status}
    </Badge>
  );
};

const GenericOutputPane = (props) => {
  const { isLoading, name, output } = props;

  return (
    <Form.Group className="formrow align-items-start">
      <Form.Label className="formlabel text-capitalize">
        {name}
        {isLoading && <Spinner animation="border" variant="secondary" size="sm" className="ms-2" />}
      </Form.Label>
      <div className="d-flex container-fluid flex-column m-0 p-0">
        {typeof output === "object" ? (
          Object.keys(output).map((s, i) => {
            return (
              <div
                className="d-flex flex-row text-capitalize border mb-1 p-1"
                style={{ fontFamily: "monospace", borderRadius: "0.25rem", backgroundColor: "#fefefe" }}
                key={i}
              >
                <div className="col-3" style={{ fontWeight: "bold" }}>
                  {s}:
                </div>
                <div className="col">{output[s]}</div>
              </div>
            );
          })
        ) : (
          <pre className="p-0 m-0">{JSON.stringify(output, null, 3)}</pre>
        )}
      </div>
    </Form.Group>
  );
};

const LogsPane = (props) => {
  const { logs, isLoading, updatedAt = null } = props;

  // Determine what type of logs we have
  const logsToShow = logs?.map?.((l) => {
    // Try parsing the log
    try {
      // TODO: Check if the logs are direct objects or stringified objects
      const parsedLog = JSON.parse(l);
      if (parsedLog.hasOwnProperty("level") && parsedLog.hasOwnProperty("t") && parsedLog.hasOwnProperty("message")) {
        return `[${formatDate(parsedLog.t, true)}] ${parsedLog.message}`;
      }
    } catch (_) {
      // Ignore error and return as string
    }
    return `${l}`;
  });

  return (
    <Form.Group className="formrow align-items-start">
      <Form.Label className="formlabel">
        Logs{isLoading && <Spinner animation="border" variant="secondary" size="sm" className="ms-2" />}
      </Form.Label>
      <div className="d-flex container-fluid flex-column p-0">
        <div
          className="d-flex container-fluid align-items-center border p-2"
          style={{
            fontFamily: "monospace",
            fontSize: "0.8rem",
            borderRadius: "0.25rem",
            minHeight: "4rem",
            maxHeight: "15rem",
            backgroundColor: "#fefefe",
            overflowY: "auto",
          }}
        >
          <pre className="p-0 m-0">{logsToShow?.join?.("\n")}</pre>
        </div>
        {updatedAt && updatedAt !== "---" && (
          <div style={{ fontSize: "0.65rem", fontWeight: "normal" }} className="align-self-end">
            Updated at: {updatedAt}
          </div>
        )}
      </div>
    </Form.Group>
  );
};

const ScenarioExecutionsPane = (props) => {
  const { svcExecId, scnExecutions = [], isLoading } = props;
  const [shownScnExecId, setShownScnExecId] = useState(null);
  const refForScnExecModal = useRef(null);

  const sortedScnExecutions = scnExecutions?.sort((a, b) => compareDates(a.updatedAt, b.updatedAt)) ?? [];

  const onStopScenarioExecution = useCallback((se) => {
    confirm(`Are you sure you want to stop this scenario?`, {
      title: `Confirm Scenario Execution Stop`,
      okText: "Stop",
      okButtonStyle: "danger",
      cancelButtonStyle: "secondary",
    }).then((confirmationResult) => {
      // Return if cancelled
      if (!confirmationResult) return;
      const svcId = se.pk?.replace(KEY_PREFIXES.SVCEXEC, "");
      const scnId = se.sk?.replace(KEY_PREFIXES.SCNEXEC, "");
      new ServiceExecutionsDataService()
        .stopScenarioExecution(svcId, scnId)
        .then((res) => {
          alert(`Scenario is being stopped. See logs and status.`);
        })
        .catch((e) => {
          console.error(e);
          alert(`Error while stopping the scenario.: ${e}`, { okText: "Ok", okButtonStyle: "danger" });
        });
    });
  }, []);

  return (
    <Form.Group className="formrow align-items-start">
      <ScenarioExecutionDetailsModal
        svcExecId={svcExecId}
        scnExecId={shownScnExecId}
        onClose={() => setShownScnExecId(null)}
        parentRef={refForScnExecModal}
      />
      <Form.Label className="formlabel">
        Scenario Executions{isLoading && <Spinner animation="border" variant="secondary" size="sm" className="ms-2" />}
      </Form.Label>
      <div className="d-flex container-fluid flex-column p-0" ref={refForScnExecModal}>
        <table className={`table table-sm table-bordered table-hover ${isLoading ? "dimmed" : ""}`}>
          <thead className="bg-dark text-light m-0 p-0" style={{ userSelect: "none" }}>
            <tr>
              <th className="bg-dark text-light small m-0 py-0">Scenario</th>
              <th className="bg-dark text-light small m-0 py-0">ID</th>
              <th className="bg-dark text-light small m-0 py-0">Description</th>
              <th className="bg-dark text-light small m-0 py-0">State</th>
              <th className="bg-dark text-light small m-0 py-0">Last Update</th>
              <th className="bg-dark text-light small m-0 py-0">Type</th>
              <th className="bg-dark text-light small m-0 py-0">Action</th>
            </tr>
          </thead>
          <tbody>
            {sortedScnExecutions.map((se, i) => {
              return (
                <tr key={`tr${i}`} className="small">
                  <td>
                    <a
                      href={`#${se.sk?.replace?.(KEY_PREFIXES.SCNEXEC, "")}`}
                      onClick={() => setShownScnExecId(se.sk?.replace?.(KEY_PREFIXES.SCNEXEC, ""))}
                    >
                      {se.svcName}
                    </a>
                  </td>
                  <td style={{ fontFamily: "monospace" }}>{truncateStringWithEllipsis(se.sk?.replace?.(KEY_PREFIXES.SCNEXEC, ""), 8)}</td>
                  <td>{se.description}</td>
                  <td>{getStatusBadge(se.state)}</td>
                  <td>
                    <pre>{formatDate(se.updatedAt)}</pre>
                  </td>
                  <td>{se.type}</td>
                  <td className="d-flex flex-row justify-content-center">
                    {ACTIVE_EXECUTION_STATES.has(se.state) ? (
                      <Button
                        variant="outline-dark"
                        className="d-flex flex-row p-1 align-items-center"
                        size="sm"
                        onClick={() => onStopScenarioExecution(se)}
                      >
                        <StopIcon size="0.75rem" />
                      </Button>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Form.Group>
  );
};

const ServiceExecutionDetails = (props) => {
  const { onClose, executionDataId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [svcExecution, setSvcExecution] = useState(null);
  const [isAutoRefreshing, setIsAutoRefreshing] = useState(true);
  const autoRefreshTimer = useRef(null);
  const isServiceActive = ACTIVE_EXECUTION_STATES.has(svcExecution?.state);
  const { Logs = [], ...allOtherOutputs } = svcExecution?.output ?? {};

  const loadServiceExecution = useCallback(() => {
    setIsLoading(true);
    let fetchedScenarios = [];
    let fetchedSvcExecution = {};
    // Fetch all scenario defs.
    new ScenarioDefinitionsDataService()
      .fetchAll()
      .then((res) => {
        fetchedScenarios = [...res];
        // Fetch the service execution
        return new ServiceExecutionsDataService().fetch(executionDataId);
      })
      .then((res) => {
        fetchedSvcExecution = { ...res?.data };
        // Fill the scenario names into scenario execs
        fetchedSvcExecution.scnExecs?.forEach?.((se) => {
          se["svcName"] = fetchedScenarios.find((scn) => scn.sk === se.scenarioDefId)?.name ?? "-";
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setSvcExecution(fetchedSvcExecution);
        setIsLoading(false);
      });
  }, [executionDataId]);

  const onAutoRefreshChange = useCallback(
    (isAutoRefresh) => {
      if (isAutoRefresh) {
        // Start auto refresh timer
        autoRefreshTimer.current = setInterval(loadServiceExecution, AUTO_REFRESH_PERIOD_MS);
      } else {
        // stop autorefresh timer
        clearInterval(autoRefreshTimer?.current);
      }
      setIsAutoRefreshing(isAutoRefresh);
      isAutoRefresh && loadServiceExecution();
    },
    [loadServiceExecution]
  );

  useEffect(() => {
    loadServiceExecution();
    if (isServiceActive) {
      // Make auto refresh enabled
      setIsAutoRefreshing(true);
      autoRefreshTimer.current = setInterval(loadServiceExecution, AUTO_REFRESH_PERIOD_MS);
    }
    return () => clearInterval(autoRefreshTimer?.current);
  }, [loadServiceExecution, isServiceActive]);

  return (
    <Modal show={true} onHide={onClose} size="xl" centered animation={true}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex flex-row align-items-baseline">
          Service Execution
          <div className="text-primary mx-2" style={{ fontFamily: "monospace", marginLeft: "1rem" }}>{`${executionDataId}`}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: "95vh",
          minHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Form.Group className="formrow">
          <Form.Label className="formlabel">
            Status{isLoading && <Spinner animation="border" variant="secondary" size="sm" className="ms-2" />}
          </Form.Label>
          <div className="d-flex flex-row align-items-center">{getStatusBadge(svcExecution?.state)}</div>
          <div className="ms-auto"></div>
          <div className={isAutoRefreshing ? "d-none" : "d-flex"}>
            <Button
              variant="secondary"
              size="sm"
              disabled={isLoading || isAutoRefreshing}
              onClick={() => {
                loadServiceExecution();
              }}
              className="px-2 py-1 d-flex"
            >
              <MdRefresh />
            </Button>
          </div>
          {isServiceActive && (
            <div className="d-flex align-items-center ms-3" style={{ fontSize: "0.75rem" }}>
              Auto Refresh
              <Switch
                className="ms-1"
                onChange={onAutoRefreshChange}
                checked={isAutoRefreshing}
                disabled={isLoading}
                height={14}
                onColor="#0d6efd"
                handleDiameter={12}
                width={30}
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </div>
          )}
        </Form.Group>
        {Object.keys(allOtherOutputs ?? {}).map?.((o, i) => {
          return <GenericOutputPane key={i} isLoading={isLoading} output={svcExecution.output[o]} name={o} />;
        })}
        {Logs && <LogsPane logs={Logs} isLoading={isLoading} updatedAt={formatDate(svcExecution?.updatedAt)} />}
        {svcExecution?.scnExecs && svcExecution?.scnExecs?.length > 0 && (
          <ScenarioExecutionsPane svcExecId={executionDataId} scnExecutions={svcExecution?.scnExecs} isLoading={isLoading} />
        )}

        <Form.Group className="formrow">
          <Form.Label className="formlabel">Description</Form.Label>
          <div>{svcExecution?.description}</div>
        </Form.Group>
        <Form.Group className="formrow">
          <Form.Label className="formlabel">Created At</Form.Label>
          <div style={{ fontFamily: "monospace" }}>{formatDate(svcExecution?.createdAt)}</div>
        </Form.Group>
        <Form.Group className="formrow align-items-start">
          <Form.Label className="formlabel">Configuration</Form.Label>
          <div
            className="d-flex container-fluid border p-2"
            style={{
              fontSize: "0.8rem",
              borderRadius: "0.25rem",
              minHeight: "4rem",
              maxHeight: "15rem",
              overflowY: "auto",
            }}
          >
            <ReactJson
              src={svcExecution?.config ?? {}}
              iconStyle="square"
              enableClipboard={false}
              indentWidth={2}
              collapsed={false}
              displayObjectSize={false}
              displayDataTypes={false}
              quotesOnKeys={false}
              name={false}
            />
          </div>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant="secondary" style={{ width: "6em" }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ServiceExecutionDetails;
