import { useCallback, useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Editor from "@monaco-editor/react";
import "./ModalStyles.css";
import { ScenarioDefinitionsDataService, ServiceDefinitionsDataService, ServiceExecutionsDataService } from "./services/tasks.service";
import { alert } from "react-bootstrap-confirmation";
import { EXECUTION_STATES, KEY_PREFIXES } from "./constants";
import { truncateStringWithEllipsis } from "./common-utils";

const styles = {
  dialogButtons: {
    width: "6em",
  },
};

const NewScenarioExecutionModal = (props) => {
  const [newScenarioExecution, setNewScenarioExecution] = useState({ description: "", config: "" });
  const [connectedServiceExecutions, setConnectedServiceExecutions] = useState([]);
  const [baseTemplate, setBaseTemplate] = useState(null);
  const [variables, setVariables] = useState([]);
  const [varValues, setVarValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const configRef = useRef(null);
  const svcExecRef = useRef(null);

  const { scnDefId } = props;
  let isConfigValid = false;
  try {
    // Try parsing config. If it has errors, it's gonna raise exception
    JSON.parse(newScenarioExecution.config);
    isConfigValid = true;
  } catch (_) {
    // If here, it means config is not a valid JSON
  }

  const onCancel = () => {
    props?.onCancel?.();
  };

  const onOk = useCallback(
    (event) => {
      // If the configuration is not a valid JSON yet, ask for user confirmation
      if (!isConfigValid) {
        alert(
          "The configuration is not a valid JSON and can cause problems for the scenario to start. Please correct it to start the service.",
          {
            title: "Invalid Configuration",
            okText: "Close",
            okButtonStyle: "secondary",
          }
        );
      } else {
        // If here we can assume we have a valid JSON in the config.
        const parsedConfig = JSON.parse(newScenarioExecution.config);
        props?.onOk?.({ ...newScenarioExecution, config: { ...parsedConfig }, svcExecId: svcExecRef?.current?.value });
      }
    },
    [newScenarioExecution, props, isConfigValid, svcExecRef]
  );

  useEffect(() => {
    setIsLoading(true);
    // Load the service def
    let scenDef = null;
    let connectedServices = [];
    let allSvcDefs = [];
    new ScenarioDefinitionsDataService()
      .fetch(scnDefId)
      .then((res) => {
        scenDef = { ...res.data };

        if (scenDef?.type) {
          return new ServiceExecutionsDataService().fetchAll(null, EXECUTION_STATES.CONNECTED);
        } else {
          return null;
        }
      })
      .then((res) => {
        connectedServices = res ?? [];

        return new ServiceDefinitionsDataService().fetchAll();
      })
      .then((res) => {
        allSvcDefs = res?.map?.((svcDef) => ({ name: svcDef.name, id: svcDef.sk })) ?? [];

        // Put name of the service definitions to the connected Services items
        connectedServices.forEach((svc) => {
          svc["svcName"] = allSvcDefs.find((svcDef) => svcDef.id === svc.serviceDefId)?.name ?? "";
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        const baseTemplate = scenDef?.template;
        if (baseTemplate) {
          let allVariables = baseTemplate.match(/__VARIABLE.*?__/g) ?? [];
          setVariables(allVariables.map((v) => [v, v.replace(/^__VARIABLE_/, "").replace(/__$/, "")]));
          setBaseTemplate(baseTemplate);
          // Set initial value of config
          setNewScenarioExecution((se) => ({ ...se, scenarioDefId: scenDef.sk, type: scenDef.type, config: baseTemplate }));
          setConnectedServiceExecutions([...connectedServices]);
        }
        setIsLoading(false);
      });
  }, [scnDefId]);

  const onVariableInputChange = useCallback(
    (event) => {
      // Which field is edited
      const fieldName = event?.target?.name;
      if (fieldName) {
        // register new var value
        const newVarValues = { ...varValues, [fieldName]: event.target.value };
        setVarValues(newVarValues);
        setNewScenarioExecution((se) => {
          se.config = baseTemplate;
          Object.keys(newVarValues).forEach((v) => {
            se.config = se.config.replace(new RegExp(`__VARIABLE_${v}__`, "g"), newVarValues[v]);
          });
          return { ...se };
        });
      }
    },
    [varValues, baseTemplate]
  );

  const onServiceChanged = useCallback((e) => {
    setNewScenarioExecution((oldSe) => {
      oldSe.pk = e.target.value;
      return { ...oldSe };
    });
  }, []);

  return (
    <Modal show={true} onHide={onCancel} size="xl" centered animation={true}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex flex-row">Load Scenario To Service</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: "70vh",
          minHeight: "60vh",
          overflowY: "auto",
        }}
      >
        <Form.Group className="formrow">
          <Form.Label className="formlabel">Service</Form.Label>
          {isLoading ? (
            <div>
              Loading... <Spinner animation="border" variant="secondary" size="sm" />
            </div>
          ) : connectedServiceExecutions.length > 0 ? (
            <Form.Select
              defaultValue={connectedServiceExecutions[0]?.sk}
              value={newScenarioExecution?.pk}
              ref={svcExecRef}
              disabled={isLoading}
              onChange={onServiceChanged}
            >
              {connectedServiceExecutions.map((s) => {
                return (
                  <option value={s.sk} key={`key${s.sk}`}>
                    {`${s.svcName} - (${truncateStringWithEllipsis(s.description, 15)}) - [${truncateStringWithEllipsis(
                      s.sk?.replace?.(KEY_PREFIXES.SVCEXEC, ""),
                      8
                    )}]`}
                  </option>
                );
              })}
            </Form.Select>
          ) : (
            <div className="text-warning">No services are available to load this scenario into.</div>
          )}
        </Form.Group>
        <Form.Group className="formrow">
          <Form.Label className="formlabel">Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Description"
            value={newScenarioExecution.description}
            onChange={(e) => setNewScenarioExecution((te) => ({ ...te, description: e.target.value }))}
            disabled={isLoading}
          />
          <Form.Control.Feedback type="invalid">Please enter a scenario execution description.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="formrow align-items-start">
          <Form.Label className="formlabel">Variables</Form.Label>
          <div
            className="d-flex flex-column border w-100 bg-light"
            style={{ borderRadius: "0.25rem", padding: ".375rem .75rem", minHeight: "2rem", opacity: variables?.length ? "1" : "0.3" }}
          >
            {isLoading && (
              <div>
                Loading... <Spinner animation="border" variant="secondary" size="sm" />
              </div>
            )}
            {!isLoading &&
              variables.map((v, i) => (
                <div className="d-flex flex-row align-items-center mb-2" key={v}>
                  <div className="col-2 text-capitalize">{v[1].replace(/_/g, " ").toLowerCase()}</div>
                  <Form.Control
                    type="text"
                    placeholder={`${v[1]} Value`}
                    onChange={onVariableInputChange}
                    name={v[1]}
                    value={varValues[i]}
                  />
                </div>
              ))}
          </div>
        </Form.Group>
        <Form.Group className="formrow align-items-start">
          <Form.Label className="formlabel">
            <div className="d-flex flex-column ">
              <div>Configuration</div>
              {!isConfigValid && (
                <div className="text-danger" style={{ fontSize: "0.5rem" }}>
                  [Configuration is not a valid JSON!]
                </div>
              )}
            </div>
          </Form.Label>
          <div className="d-flex p-0 col mb-3" style={{ height: "20em" }}>
            <Editor
              language="json"
              className="form-control form-control-sm p-0"
              options={{
                scrollBeyondLastLine: false,
                minimap: { enabled: false },
                lineNumbers: false,
                glyphMargin: false,
                lineDecorationsWidth: 0,
                renderLineHighlight: "none",
              }}
              value={newScenarioExecution.config}
              onChange={() => {
                configRef?.current?.setValue(newScenarioExecution.config);
              }}
              onMount={(ed) => {
                configRef.current = ed;
              }}
            />
          </div>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} variant="secondary" style={styles.dialogButtons}>
          Close
        </Button>
        <Button onClick={onOk} variant="primary" style={styles.dialogButtons} disabled={connectedServiceExecutions.length < 1}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewScenarioExecutionModal;
