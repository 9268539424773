import React, { useCallback, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import QuadifiedLogo from "./assets/images/Quadified_N_H.png";
import SignInForm from "./SignInForm";
import authService from "./services/auth.service";
import Nav from "react-bootstrap/Nav";
import Sidebar from "./Sidebar";
import ServiceDefinitions from "./ServiceDefinitions";
import ScenarioDefinitions from "./ScenarioDefinitions";
import ServiceExecutions from "./ServiceExecutions";
import { Switch, Route, Link, useRouteMatch, Redirect } from "react-router-dom";
import { BsFileCode } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { GrDocumentTime, GrSystem } from "react-icons/gr";
import "./App.css";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [authenticatedSession, setAuthenticatedSession] = useState(null);
  useRouteMatch();

  const checkAuthSession = useCallback(async () => {
    // Check user signing
    try {
      const currentSession = await authService.getCurrentSession();
      setAuthenticatedSession({ ...currentSession, user: await authService.getCurrentUserInfo() });
    } catch (e) {
      // Ignore error. Sign In page will show
      setAuthenticatedSession(null);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await checkAuthSession();
      setIsLoading(false);
    })();
  }, [checkAuthSession]);

  const onSignOut = useCallback(async () => {
    await authService.signOut();
    setAuthenticatedSession(null);
  }, []);

  return (
    <div className="container-fluid h-100 m-0 p-0" style={{ flexShrink: 0 }}>
      <Navbar expand="md" sticky="top" style={{ height: "50px", backgroundColor: "#003" }}>
        <Container fluid>
          <Navbar.Brand href="/">
            <Container className="d-flex flex-row align-items-center p-0">
              <img src={QuadifiedLogo} alt="Quadified Logo" height="25" />
              <Container className="m-0 text-light" style={{ fontSize: "1rem" }}>
                QuadSim Control Panel
              </Container>
            </Container>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {authenticatedSession && (
              <Nav className="ms-auto d-flex flex-row align-items-center">
                <Navbar.Text className="d-flex flex-row text-light align-items-center" style={{ fontSize: "0.75rem" }}>
                  <FaUserCircle size="1.5em" className="me-1" />
                  <b>{authenticatedSession.user?.attributes?.email}</b>
                </Navbar.Text>
                <Button
                  variant="outline-light"
                  onClick={onSignOut}
                  size="sm"
                  className="mx-2 p-0 px-1 btn-rounded"
                  style={{ borderRadius: "1rem", fontSize: "0.75rem" }}
                >
                  Sign Out
                </Button>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="d-flex" style={{ height: "calc(100% - 50px)" }}>
        {isLoading ? (
          <div className="h4 m-1">Loading...</div>
        ) : authenticatedSession === null ? (
          <SignInForm onAuthenticatedSignin={() => checkAuthSession()} />
        ) : (
          <>
            <Sidebar>
              <Nav activeKey={window.location.pathname} className="flex-column m-0 p-0" variant="pills">
                <Nav.Link as={Link} to="/servicedefs" eventKey="/servicedefs">
                  <div className="d-flex flex-row align-items-center">
                    <BsFileCode size="1.8em" />
                    <div className="mx-2">Service Definitions</div>
                  </div>
                </Nav.Link>
                <Nav.Link as={Link} to="/scenariodefs" eventKey="/scenariodefs">
                  <div className="d-flex flex-row align-items-center">
                    <GrDocumentTime size="1.8em" />
                    <div className="mx-2">Scenario Definitions</div>
                  </div>
                </Nav.Link>
                <hr />
                <Nav.Link as={Link} to="/serviceexecs" eventKey="/serviceexecs">
                  <div className="d-flex flex-row align-items-center">
                    <GrSystem size="1.8em" />
                    <div className="mx-2">Service Executions</div>
                  </div>
                </Nav.Link>
              </Nav>
            </Sidebar>
            <div className="container-fluid overflow-auto p-1 m-0" style={{ flexGrow: 1 }}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/serviceexecs" />
                </Route>
                <Route exact path="/servicedefs" render={(props) => <ServiceDefinitions user={authenticatedSession?.user} {...props} />} />
                <Route
                  exact
                  path="/scenariodefs"
                  render={(props) => <ScenarioDefinitions user={authenticatedSession?.user} {...props} />}
                />
                <Route exact path="/serviceexecs" render={(props) => <ServiceExecutions user={authenticatedSession?.user} {...props} />} />
                <Route
                  render={function () {
                    return <p>Not found</p>;
                  }}
                />
              </Switch>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default App;
