const moment = require("moment");

const formatDate = (dateString, subSecond = false) => {
  let m = null;
  if (typeof dateString === "number") {
    // Decode as unix time (convert to ms)
    m = moment(dateString * 1000);
  } else {
    m = moment(dateString, [moment.ISO_8601, "YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z"]);
  }
  if (m.isValid()) {
    return m.local().format("YYYY-MM-DD HH:mm:ss" + (subSecond ? ".SSS" : ""));
  }
  // Return unknown time indicator
  return "---";
};

function truncateStringWithEllipsis(str, n) {
  if (str.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
}

function compareDates(d1, d2) {
  let m1 = null;
  let m2 = null;
  if (typeof d1 === "number") {
    // Decode as unix time (convert to ms)
    m1 = moment(d1 * 1000);
  } else {
    m1 = moment(d1, [moment.ISO_8601, "YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z"]);
  }
  if (typeof d2 === "number") {
    // Decode as unix time (convert to ms)
    m2 = moment(d2 * 1000);
  } else {
    m2 = moment(d2, [moment.ISO_8601, "YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z"]);
  }

  return m1.isBefore(m2) ? 1 : -1;
}

export { formatDate, truncateStringWithEllipsis, compareDates };
