import React, { useEffect, useState, useCallback } from "react";
import { GrSystem } from "react-icons/gr";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { KEY_PREFIXES, EXECUTIONSTATECOLORMAPS, ACTIVE_EXECUTION_STATES } from "./constants";
import PagedTable from "./PagedTable";
import { formatDate, truncateStringWithEllipsis } from "./common-utils";
import Badge from "react-bootstrap/Badge";
import { FaTrash as DeleteIcon, FaHandPaper as StopIcon } from "react-icons/fa";
import { ServiceExecutionsDataService, ServiceDefinitionsDataService } from "./services/tasks.service";
import ServiceExecutionDetails from "./ServiceExecutionDetails";
import { alert, confirm } from "react-bootstrap-confirmation";

const getStatusBadge = (status) => {
  return (
    <Badge pill bg={EXECUTIONSTATECOLORMAPS[status] ?? "secondary"} style={{ width: "8rem" }}>
      {status}
    </Badge>
  );
};

const commonColumns = [
  {
    key: "svcDefName",
    text: "Service Definition",
    sortable: true,
    modify: (s, pk, sk, { onServiceExecClicked, ...otherExtras }) => {
      return (
        <a
          href={`#${sk.replace(KEY_PREFIXES.SVCEXEC, "")}`}
          onClick={() => {
            onServiceExecClicked?.(sk.replace(KEY_PREFIXES.SVCEXEC, ""));
          }}
        >
          {`${s}`}
        </a>
      );
    },
  },
  {
    key: "sk",
    text: "Id",
    modify: (s, pk, sk, { onServiceExecClicked, ...otherExtras }) => {
      return (
        <a
          href={`#${sk.replace(KEY_PREFIXES.SVCEXEC, "")}`}
          style={{ fontFamily: "monospace" }}
          onClick={() => {
            onServiceExecClicked?.(sk.replace(KEY_PREFIXES.SVCEXEC, ""));
          }}
        >
          {truncateStringWithEllipsis(`${s.replace(KEY_PREFIXES.SVCEXEC, "")}`, 8)}
        </a>
      );
    },
    sortable: true,
  },
  { key: "type", text: "Type", sortable: true },
  {
    key: "state",
    text: "Status",
    sortable: true,
    modify: (d, pk, sk) => {
      return getStatusBadge(d);
    },
  },
  {
    key: "updatedAt",
    text: "Updated At",
    modify: (d, pk, sk) => {
      return <pre>{formatDate(d)}</pre>;
    },
    sortable: true,
  },
  {
    key: "createdAt",
    text: "Created At",
    modify: (d, pk, sk) => {
      return <pre>{formatDate(d)}</pre>;
    },
    sortable: true,
  },
  {
    key: "description",
    text: "Description",
    sortable: true,
  },
];
const activeExecColumns = [
  ...commonColumns,
  {
    key: "sk",
    text: "Actions",
    modify: (svcExecName, pk, sk, extra) => {
      return (
        <div className="d-flex flex-row justify-content-center" style={{ height: "2em" }}>
          <ButtonGroup size="sm">
            <Button
              variant="outline-dark"
              className="d-flex flex-row p-0 px-2 align-items-center"
              size="sm"
              onClick={() => extra?.onStopExecution?.(sk)}
            >
              <StopIcon size="0.75rem" />
            </Button>
            <Button
              variant="outline-danger"
              className="d-flex flex-row p-0 px-2 align-items-center"
              size="sm"
              onClick={() => extra?.onDeleteExecution?.(sk, true)}
            >
              <DeleteIcon size="0.75rem" />
            </Button>
          </ButtonGroup>
        </div>
      );
    },
  },
];
const completedExecColumns = [
  ...commonColumns,
  {
    key: "sk",
    text: "Delete",
    modify: (svcExecName, pk, sk, extra) => {
      return (
        <div className="d-flex flex-row justify-content-center" style={{ height: "2em" }}>
          <Button
            variant="outline-danger"
            className="d-flex flex-row p-0 px-2 align-items-center"
            size="sm"
            onClick={() => extra?.onDeleteExecution?.(sk, false)}
          >
            <DeleteIcon size="0.75rem" />
          </Button>
        </div>
      );
    },
  },
];

const categorizeExecutions = (svcExecutions) => {
  return {
    active: svcExecutions.filter((se) => ACTIVE_EXECUTION_STATES.has(se.state)),
    inactive: svcExecutions.filter((se) => !ACTIVE_EXECUTION_STATES.has(se.state)),
  };
};

const ServiceExecutions = (props) => {
  const [serviceExecutions, setServiceExecutions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shownExecutionId, setShownExecutionId] = useState(null);

  /// Group the executions as active and inactive
  const groupedExecutions = categorizeExecutions(serviceExecutions) ?? { active: [], inactive: [] };

  const loadAllItems = useCallback(() => {
    setIsLoading(true);
    let allSvcExecs = [];
    let allSvcDefs = [];
    new ServiceExecutionsDataService()
      .fetchAll()
      .then((res) => {
        allSvcExecs = res;
        return new ServiceDefinitionsDataService().fetchAll();
      })
      .then((res) => {
        allSvcDefs = res;
        // Put in the name of the service definition
        allSvcExecs.forEach((se) => {
          // find the service def
          const foundSvcDef = allSvcDefs.find((sd) => sd.sk === se.serviceDefId);
          // Assign the name if found, or put "---" as name
          se["svcDefName"] = foundSvcDef?.name ?? "---";
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setServiceExecutions(allSvcExecs);
        setIsLoading(false);
      });
  }, []);

  const onRefresh = useCallback(() => {
    loadAllItems();
  }, [loadAllItems]);

  const onDeleteExecution = useCallback(
    (sk, isServiceActive) => {
      const id = sk?.replace?.(KEY_PREFIXES.SVCEXEC, "");
      const confirmationMessage = isServiceActive
        ? `Are you sure you want to delete this ACTIVE service execution before stopping it? [${id}]?`
        : `Are you sure you want to delete this service execution? [${id}]?`;

      confirm(confirmationMessage, {
        title: `Confirm Service Execution Stop`,
        okText: "Delete",
        okButtonStyle: "danger",
        cancelButtonStyle: "secondary",
      }).then((confirmationResult) => {
        // Return if cancelled
        if (!confirmationResult) return;
        setIsLoading(true);
        new ServiceExecutionsDataService()
          .operationOnMultiple({ DELETE: [id] })
          .then((res) => {
            if (res.data?.error === true) {
              alert(`Error while deleting the service execution "${id}": ${res.data?.reason}`, { okText: "Ok", okButtonStyle: "danger" });
              setIsLoading(false);
            } else {
              // Service is stopped. now refresh
              onRefresh();
            }
          })
          .catch((e) => {
            console.error(e);
            alert(`Error while deleting the service execution "${id}".`, { okText: "Ok", okButtonStyle: "danger" });
          });
      });
    },
    [onRefresh]
  );

  const onStopExecution = useCallback(
    (sk) => {
      const id = sk?.replace?.(KEY_PREFIXES.SVCEXEC, "");
      confirm(`Are you sure you want to stop the service execution "${id}"?`, {
        title: `Confirm Service Execution Stop`,
        okText: "Stop",
        okButtonStyle: "danger",
        cancelButtonStyle: "secondary",
      }).then((confirmationResult) => {
        // Return if cancelled
        if (!confirmationResult) return;
        setIsLoading(true);
        new ServiceExecutionsDataService()
          .operationOnMultiple({ STOP: [id] })
          .then((res) => {
            if (res.data?.error === true) {
              alert(`Error while stopping the service execution "${id}": ${res.data?.reason}`, { okText: "Ok", okButtonStyle: "danger" });
              setIsLoading(false);
            } else {
              // Service is stopped. now refresh
              onRefresh();
            }
          })
          .catch((e) => {
            console.error(e);
            alert(`Error while stopping the service execution "${id}".`, { okText: "Ok", okButtonStyle: "danger" });
          });
      });
    },
    [onRefresh]
  );

  const onServiceExecClicked = useCallback((id) => {
    setShownExecutionId(id);
  }, []);

  useEffect(() => {
    loadAllItems();
  }, [loadAllItems]);

  return (
    <div className="container flex-column m-0 p-0 h-100">
      <div className="container-fluid d-flex flex-row align-items-center mb-2 justify-content-between">
        <div className="h2 m-0">Service Executions</div>
        <GrSystem className="h2 m-0 me-2" />
      </div>
      {shownExecutionId && <ServiceExecutionDetails executionDataId={shownExecutionId} onClose={() => setShownExecutionId(null)} />}
      <div
        className="d-flex flex-column m-3 border p-4"
        style={{ borderRadius: "1rem", minHeight: "15rem", backgroundColor: "rgb(250,255,255)" }}
      >
        <div className="h4">Active Executions</div>
        <PagedTable
          numbers
          isLoading={isLoading}
          data={groupedExecutions.active}
          itemsPerPage={10}
          columns={activeExecColumns}
          onRefresh={onRefresh}
          extra={{ onServiceExecClicked: onServiceExecClicked, onStopExecution: onStopExecution, onDeleteExecution: onDeleteExecution }}
          filterableColumns={["sk", "executionStatus", "description", "svcDefName", "createdBy"]}
          initialSortCriteria={{ key: "createdAt", ascending: false }}
        />
      </div>
      <div className="d-flex flex-column m-3 border bg-light p-4" style={{ borderRadius: "1rem", minHeight: "15rem" }}>
        <div className="h4">Completed Executions</div>
        <PagedTable
          numbers
          isLoading={isLoading}
          data={groupedExecutions.inactive}
          itemsPerPage={10}
          columns={completedExecColumns}
          extra={{ onServiceExecClicked: onServiceExecClicked, onDeleteExecution: onDeleteExecution }}
          filterableColumns={["sk", "executionStatus", "description", "svcDefName", "createdBy"]}
          initialSortCriteria={{ key: "createdAt", ascending: false }}
        />
      </div>
    </div>
  );
};
export default ServiceExecutions;
