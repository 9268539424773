import { useCallback, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ModalStyles.css";
import { ServiceExecutionsDataService } from "./services/tasks.service";
import { EXECUTIONSTATECOLORMAPS, ACTIVE_EXECUTION_STATES, KEY_PREFIXES } from "./constants";
import Badge from "react-bootstrap/Badge";
import { formatDate } from "./common-utils";
import { MdRefresh } from "react-icons/md";
import Switch from "react-switch";
import ReactJson from "react-json-view";

const getStatusBadge = (status) => {
  return (
    <Badge pill bg={EXECUTIONSTATECOLORMAPS[status] ?? "secondary"} style={{ width: "8rem" }}>
      {status}
    </Badge>
  );
};

// TODO: Refactor this component. So many duplication of reused sub-components
const GenericOutputPane = (props) => {
  const { isLoading, name, output } = props;

  return (
    <Form.Group className="formrow align-items-start">
      <Form.Label className="formlabel text-capitalize">
        {name}
        {isLoading && <Spinner animation="border" variant="secondary" size="sm" className="ms-2" />}
      </Form.Label>
      <div className="d-flex container-fluid flex-column m-0 p-0">
        {typeof output === "object" ? (
          Object.keys(output).map((s, i) => {
            return (
              <div
                className="d-flex flex-row text-capitalize border mb-1 p-1"
                style={{ fontFamily: "monospace", borderRadius: "0.25rem", backgroundColor: "#fefefe" }}
                key={i}
              >
                <div className="col-3" style={{ fontWeight: "bold" }}>
                  {s}:
                </div>
                <div className="col">{output[s]}</div>
              </div>
            );
          })
        ) : (
          <pre className="p-0 m-0">{JSON.stringify(output, null, 3)}</pre>
        )}
      </div>
    </Form.Group>
  );
};

const LogsPane = (props) => {
  const { logs, isLoading, updatedAt = null } = props;

  // Determine what type of logs we have
  const logsToShow = logs?.map?.((l) => {
    // Try parsing the log
    try {
      // TODO: Check if the logs are direct objects or stringified objects
      const parsedLog = JSON.parse(l);
      if (parsedLog.hasOwnProperty("level") && parsedLog.hasOwnProperty("t") && parsedLog.hasOwnProperty("message")) {
        return `[${formatDate(parsedLog.t, false)}] ${parsedLog.message}`;
      }
    } catch (_) {
      // Ignore error and return as string
    }
    return `${l}`;
  });

  return (
    <Form.Group className="formrow align-items-start">
      <Form.Label className="formlabel">
        Logs{isLoading && <Spinner animation="border" variant="secondary" size="sm" className="ms-2" />}
      </Form.Label>
      <div className="d-flex container-fluid flex-column p-0">
        <div
          className="d-flex container-fluid align-items-center border p-2"
          style={{
            fontFamily: "monospace",
            fontSize: "0.8rem",
            borderRadius: "0.25rem",
            minHeight: "4rem",
            maxHeight: "15rem",
            backgroundColor: "#fefefe",
            overflowY: "auto",
          }}
        >
          <pre className="p-0 m-0">{logsToShow?.join?.("\n")}</pre>
        </div>
        {updatedAt && updatedAt !== "---" && (
          <div style={{ fontSize: "0.65rem", fontWeight: "normal" }} className="align-self-end">
            Updated at: {updatedAt}
          </div>
        )}
      </div>
    </Form.Group>
  );
};

const ScenarioExecutionDetailsModal = (props) => {
  const { svcExecId, scnExecId, onClose, parentRef } = props;
  const [scenarioExecution, setScenarioExecution] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const id = scnExecId?.replace?.(KEY_PREFIXES.SCNEXEC, "") ?? "";
  const isScenarioActive = ACTIVE_EXECUTION_STATES.has(scenarioExecution?.state);
  const { Logs = [], ...allOtherOutputs } = scenarioExecution?.output ?? {};

  // TODO: Implement auto refresh
  const isAutoRefreshing = false;
  const onAutoRefreshChange = null;

  const loadScenarioExecution = useCallback(() => {
    if (!scnExecId) return;
    setIsLoading(true);
    new ServiceExecutionsDataService()
      .fetchScenarioExecution(svcExecId, scnExecId)
      .then((res) => {
        setScenarioExecution(res?.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [svcExecId, scnExecId]);

  useEffect(() => {
    loadScenarioExecution();
  }, [loadScenarioExecution]);

  return (
    <Modal
      show={scnExecId != null}
      onHide={() => {
        setScenarioExecution(null);
        onClose?.();
      }}
      size="lg"
      centered
      animation={true}
      container={parentRef?.current}
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex flex-row align-items-baseline">
          Service Execution
          <div className="text-primary mx-2" style={{ fontFamily: "monospace", marginLeft: "1rem" }}>{`${id}`}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="formrow">
          <Form.Label className="formlabel">
            Status{isLoading && <Spinner animation="border" variant="secondary" size="sm" className="ms-2" />}
          </Form.Label>
          <div className="d-flex flex-row align-items-center">{getStatusBadge(scenarioExecution?.state)}</div>
          <div className="ms-auto"></div>
          <div className={isAutoRefreshing ? "d-none" : "d-flex"}>
            <Button
              variant="secondary"
              size="sm"
              disabled={isLoading || isAutoRefreshing}
              onClick={() => {
                loadScenarioExecution();
              }}
              className="px-2 py-1 d-flex"
            >
              <MdRefresh />
            </Button>
          </div>
          {isScenarioActive && false && (
            <div className="d-flex align-items-center ms-3" style={{ fontSize: "0.75rem" }}>
              Auto Refresh
              <Switch
                className="ms-1"
                onChange={onAutoRefreshChange}
                checked={isAutoRefreshing}
                disabled={isLoading}
                height={14}
                onColor="#0d6efd"
                handleDiameter={12}
                width={30}
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </div>
          )}
        </Form.Group>
        {Object.keys(allOtherOutputs ?? {}).map?.((o, i) => {
          return <GenericOutputPane key={i} isLoading={isLoading} output={scenarioExecution?.output?.[o]} name={o} />;
        })}
        {Logs && <LogsPane logs={Logs} isLoading={isLoading} updatedAt={formatDate(scenarioExecution?.updatedAt, false)} />}
        <Form.Group className="formrow">
          <Form.Label className="formlabel">Description</Form.Label>
          <div>{scenarioExecution?.description}</div>
        </Form.Group>
        <Form.Group className="formrow align-items-start">
          <Form.Label className="formlabel">Configuration</Form.Label>

          <div
            className="d-flex container-fluid border p-2"
            style={{
              fontSize: "0.8rem",
              borderRadius: "0.25rem",
              minHeight: "4rem",
              maxHeight: "15rem",
              overflowY: "auto",
            }}
          >
            <ReactJson
              src={scenarioExecution?.config ?? {}}
              iconStyle="square"
              enableClipboard={false}
              indentWidth={2}
              collapsed={false}
              displayObjectSize={false}
              displayDataTypes={false}
              quotesOnKeys={false}
              name={false}
            />
          </div>
        </Form.Group>
      </Modal.Body>
    </Modal>
  );
};

export default ScenarioExecutionDetailsModal;
