import React, { useState, useEffect, useCallback } from "react";
import { ScenarioDefinitionsDataService, ServiceExecutionsDataService } from "./services/tasks.service";
import { Link } from "react-router-dom";
import ScenarioDefinitionEditModal from "./ScenarioDefinitionEditModal";
import { KEY_PREFIXES } from "./constants";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaTrash as DeleteIcon, FaRegCopy as DuplicateIcon } from "react-icons/fa";
import NewScenarioExecutionModal from "./NewScenarioExecutionModal";
import { alert, confirm } from "react-bootstrap-confirmation";
import { formatDate } from "./common-utils";
import PagedTable from "./PagedTable";
import { GrDocumentTime } from "react-icons/gr";
import { ImFilePlay as RunIcon } from "react-icons/im";

const ITEMS_PER_PAGE = 15;
const columns = [
  {
    key: "name",
    text: "Name",
    modify: (n, pk, sk, extra) => {
      return <Link to="#" onClick={() => extra?.onEditScenarioDef(sk)}>{`${n}`}</Link>;
    },
    sortable: true,
  },
  { key: "description", text: "Description", sortable: true },
  { key: "type", text: "Type", sortable: true },
  {
    key: "createdAt",
    text: "Created",
    modify: (d, pk, sk) => {
      return <pre>{formatDate(d)}</pre>;
    },
    sortable: true,
  },
  {
    key: "start",
    text: "Actions",
    modify: (n, pk, sk, extra) => {
      return (
        <div className="d-flex flex-row justify-content-center" style={{ height: "2em" }}>
          <ButtonGroup size="sm">
            <Button
              variant="outline-dark"
              className="d-flex flex-row p-0 px-2 align-items-center"
              size="sm"
              onClick={() => extra?.onLoadScenario?.(sk)}
            >
              <RunIcon size="0.75rem" />
            </Button>
            <Button
              variant="outline-secondary"
              className="d-flex flex-row p-0 px-2 align-items-center"
              size="sm"
              onClick={() => extra?.onDuplicate?.(sk)}
            >
              <DuplicateIcon size="0.75rem" />
            </Button>
            <Button
              variant="outline-danger"
              className="d-flex flex-row p-0 px-2 align-items-center"
              size="sm"
              onClick={() => extra?.onDeleteScenario?.(sk, n)}
            >
              <DeleteIcon size="0.75rem" />
            </Button>
          </ButtonGroup>
        </div>
      );
    },
  },
];

const ScenarioDefinitions = (props) => {
  const [scenarioDefinitions, setScenarioDefinitions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editedScenarioDef, setEditedScenarioDef] = useState(null);
  const [scenarioIdToExecute, setScenarioIdToExecute] = useState(null);

  const loadItems = useCallback(() => {
    setIsLoading(true);
    new ScenarioDefinitionsDataService()
      .fetchAll()
      .then((res) => {
        setScenarioDefinitions(res);
      })
      .catch((e) => {
        console.error("Error when loading scenario definitions:", e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onRefresh = useCallback(() => {
    loadItems();
  }, [loadItems]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const onCreateNewScenarioDef = useCallback(() => {
    setEditedScenarioDef({
      name: "",
      type: "",
      description: "",
      createdBy: "",
      sk: "-1",
      pk: "-1",
      template: "",
    });
  }, []);

  const onEditScenarioDef = (scenarioDefSk, duplicate = false) => {
    const id = scenarioDefSk?.replace(KEY_PREFIXES.SCNDEF, "");
    if (id) {
      setIsLoading(true);
      (async () => {
        try {
          const res = await new ScenarioDefinitionsDataService().fetch(id);

          if (res.data) {
            // If we're duplicating this scenario, change sk and name
            const duplicationPayload = duplicate ? { pk: "-1", name: `Copy of ${res?.data?.name ?? ""}` } : {};
            setEditedScenarioDef({ ...res.data, ...duplicationPayload });
          }
        } catch (e) {
          console.error(e);
        }
        setIsLoading(false);
      })();
    }
  };

  const onModalOk = useCallback(
    (newScenarioDef) => {
      // Generate the new scenario def. Remove problematic values to update
      const { pk, sk, updatedAt, createdBy, createdAt, updatedBy, ...updatedScenarioDef } = { ...editedScenarioDef, ...newScenarioDef };
      const ds = new ScenarioDefinitionsDataService();
      const methodToCall = pk === "-1" ? (_, entity) => ds.create(entity) : (id, entity) => ds.update(id, entity);

      if (pk === "-1") {
        // Assign createdBy field from user from current session
        updatedScenarioDef["createdBy"] = props?.user?.attributes?.email;
      }

      methodToCall(sk.replace(KEY_PREFIXES.SCNDEF, ""), { ...updatedScenarioDef })
        .then((res) => {
          onRefresh();
        })
        .catch((e) => {
          console.error("update error: ", e);
        });
      setEditedScenarioDef(null);
    },
    [editedScenarioDef, onRefresh, props]
  );

  const onDeleteScenario = useCallback(
    (scenarioDefId, scnName) => {
      confirm(`Are you sure you want to delete the scenario definition "${scnName}"?`, {
        title: `Confirm Scenario Definition Delete`,
        okText: "Confirm",
        okButtonStyle: "danger",
        cancelButtonStyle: "secondary",
      }).then((confirmationResult) => {
        // Return if cancelled
        if (!confirmationResult) return;
        setIsLoading(true);
        new ScenarioDefinitionsDataService()
          .delete(scenarioDefId?.replace?.(KEY_PREFIXES.SCNDEF, ""))
          .then((res) => {
            if (res.data?.error === true) {
              alert(`Error while deleting the scenario "${scnName}": ${res.data?.reason}`, { okText: "Ok", okButtonStyle: "danger" });
              setIsLoading(false);
            } else {
              // Scenario is deleted. now refresh
              onRefresh();
            }
          })
          .catch((e) => {
            console.error(e);
            alert(`Error while deleting the scenario "${scnName}".`, { okText: "Ok", okButtonStyle: "danger" });
          });
      });
    },
    [onRefresh]
  );

  const onLoadScenario = useCallback((scenarioDefId) => {
    setScenarioIdToExecute(scenarioDefId?.replace?.(KEY_PREFIXES.SCNDEF, ""));
  }, []);

  const onNewScenarioExecOk = useCallback((newScenarioExecution) => {
    const svcExecId = newScenarioExecution?.svcExecId?.replace?.(KEY_PREFIXES.SVCEXEC, "");
    if (svcExecId) {
      new ServiceExecutionsDataService()
        .createScenarioExecution(svcExecId, {
          scenarioDefId: newScenarioExecution.scenarioDefId,
          description: newScenarioExecution.description,
          config: newScenarioExecution.config,
          type: newScenarioExecution.type,
        })
        .then((res) => {
          return alert(`The scenario is loaded to the service. Check the logs and status.`);
        })
        .catch((e) => {
          console.error(e);
          alert(`Error while loading the scenario.`, { okText: "Ok", okButtonStyle: "danger" });
        })
        .finally(() => {
          setScenarioIdToExecute(null);
        });
    }
  }, []);

  return (
    <div className="container flex-column m-0 p-0 h-100">
      {editedScenarioDef && (
        <ScenarioDefinitionEditModal
          modalVisible={true}
          onCancel={() => setEditedScenarioDef(null)}
          onOk={onModalOk}
          editedScenarioDef={editedScenarioDef}
        />
      )}
      {scenarioIdToExecute && (
        <NewScenarioExecutionModal
          scnDefId={scenarioIdToExecute}
          onCancel={() => setScenarioIdToExecute(null)}
          onOk={onNewScenarioExecOk}
        />
      )}
      <div className="container-fluid d-flex flex-row align-items-center mb-2 justify-content-between">
        <div className="h2 m-0">Scenario Definitions</div>
        <GrDocumentTime className="h2 m-0 me-2" />
      </div>
      <div className="d-flex flex-column m-3 border bg-light p-4" style={{ borderRadius: "1rem", minHeight: "15rem" }}>
        <PagedTable
          numbers
          isLoading={isLoading}
          data={scenarioDefinitions}
          itemsPerPage={ITEMS_PER_PAGE}
          columns={columns}
          onCreate={onCreateNewScenarioDef}
          extra={{
            onEditScenarioDef: onEditScenarioDef,
            onDuplicate: (sk) => onEditScenarioDef(sk, true),
            onLoadScenario: onLoadScenario,
            onDeleteScenario: onDeleteScenario,
          }}
          initialSortCriteria={{ key: "createdAt", ascending: false }}
          filterableColumns={["name", "description", "type"]}
        />
      </div>
    </div>
  );
};
export default ScenarioDefinitions;
