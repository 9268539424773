import React, { useState, useEffect, useCallback } from "react";
import { ServiceDefinitionsDataService, ServiceExecutionsDataService } from "./services/tasks.service";
import { Link } from "react-router-dom";
import ServiceDefinitionEditModal from "./ServiceDefinitionEditModal";
import { KEY_PREFIXES } from "./constants";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaPlay as RunIcon, FaTrash as DeleteIcon, FaRegCopy as DuplicateIcon } from "react-icons/fa";
import NewServiceExecutionModal from "./NewServiceExecutionModal";
import { alert, confirm } from "react-bootstrap-confirmation";
import { formatDate } from "./common-utils";
import PagedTable from "./PagedTable";
import { BsFileCode } from "react-icons/bs";

const ITEMS_PER_PAGE = 15;
const columns = [
  {
    key: "name",
    text: "Name",
    modify: (n, pk, sk, extra) => {
      return <Link to="#" onClick={() => extra?.onEditServiceDef(sk)}>{`${n}`}</Link>;
    },
    sortable: true,
  },
  { key: "description", text: "Description", sortable: true },
  { key: "type", text: "Type", sortable: true },
  {
    key: "createdAt",
    text: "Created",
    modify: (d, pk, sk) => {
      return <pre>{formatDate(d)}</pre>;
    },
    sortable: true,
  },
  {
    key: "start",
    text: "Actions",
    modify: (n, pk, sk, extra) => {
      return (
        <div className="d-flex flex-row justify-content-center" style={{ height: "2em" }}>
          <ButtonGroup size="sm">
            <Button
              variant="outline-dark"
              className="d-flex flex-row p-0 px-2 align-items-center"
              size="sm"
              onClick={() => extra?.onStartNewService?.(sk)}
            >
              <RunIcon size="0.75rem" />
            </Button>
            <Button
              variant="outline-secondary"
              className="d-flex flex-row p-0 px-2 align-items-center"
              size="sm"
              onClick={() => extra?.onDuplicate?.(sk)}
            >
              <DuplicateIcon size="0.75rem" />
            </Button>
            <Button
              variant="outline-danger"
              className="d-flex flex-row p-0 px-2 align-items-center"
              size="sm"
              onClick={() => extra?.onDeleteService?.(sk, n)}
            >
              <DeleteIcon size="0.75rem" />
            </Button>
          </ButtonGroup>
        </div>
      );
    },
  },
];

const ServiceDefinitions = (props) => {
  const [serviceDefinitions, setServiceDefinitions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editedServiceDef, setEditedServiceDef] = useState(null);
  const [serviceDefForExecution, setServiceDefForExecution] = useState(null);

  const loadItems = useCallback(() => {
    setIsLoading(true);
    new ServiceDefinitionsDataService()
      .fetchAll()
      .then((res) => {
        setServiceDefinitions(res);
      })
      .catch((e) => {
        console.error("Error when loading service definitions:", e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onRefresh = useCallback(() => {
    loadItems();
  }, [loadItems]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const onCreateNewServiceDef = useCallback(() => {
    setEditedServiceDef({
      name: "",
      type: "",
      description: "",
      createdBy: "",
      sk: "-1",
      pk: "-1",
      template: "",
    });
  }, []);

  const onEditServiceDef = (serviceDefSk, duplicate = false) => {
    const id = serviceDefSk?.replace(KEY_PREFIXES.SVCDEF, "");
    if (id) {
      setIsLoading(true);
      (async () => {
        try {
          const res = await new ServiceDefinitionsDataService().fetch(id);

          if (res.data) {
            // If we're duplicating this service, change sk and name
            const duplicationPayload = duplicate ? { pk: "-1", name: `Copy of ${res?.data?.name ?? ""}` } : {};
            setEditedServiceDef({ ...res.data, ...duplicationPayload });
          }
        } catch (e) {
          console.error(e);
        }
        setIsLoading(false);
      })();
    }
  };

  const onModalOk = useCallback(
    (newServiceDef) => {
      // Generate the new service def. Remove problematic values to update
      const { pk, sk, updatedAt, createdBy, createdAt, updatedBy, ...updatedServiceDef } = { ...editedServiceDef, ...newServiceDef };
      const ds = new ServiceDefinitionsDataService();
      const methodToCall = pk === "-1" ? (_, entity) => ds.create(entity) : (id, entity) => ds.update(id, entity);

      if (pk === "-1") {
        // Assign createdBy field from user from current session
        updatedServiceDef["createdBy"] = props?.user?.attributes?.email;
      }

      methodToCall(sk.replace(KEY_PREFIXES.SVCDEF, ""), { ...updatedServiceDef })
        .then((res) => {
          onRefresh();
        })
        .catch((e) => {
          console.error("update error: ", e);
        });
      setEditedServiceDef(null);
    },
    [editedServiceDef, onRefresh, props]
  );

  const onDeleteService = useCallback(
    (serviceDefId, svcName) => {
      confirm(`Are you sure you want to delete the service definition "${svcName}"?`, {
        title: `Confirm Service Definition Delete`,
        okText: "Confirm",
        okButtonStyle: "danger",
        cancelButtonStyle: "secondary",
      }).then((confirmationResult) => {
        // Return if cancelled
        if (!confirmationResult) return;
        setIsLoading(true);
        new ServiceDefinitionsDataService()
          .delete(serviceDefId?.replace?.(KEY_PREFIXES.SVCDEF, ""))
          .then((res) => {
            if (res.data?.error === true) {
              alert(`Error while deleting the service "${svcName}": ${res.data?.reason}`, { okText: "Ok", okButtonStyle: "danger" });
              setIsLoading(false);
            } else {
              // Service is deleted. now refresh
              onRefresh();
            }
          })
          .catch((e) => {
            console.error(e);
            alert(`Error while deleting the service "${svcName}".`, { okText: "Ok", okButtonStyle: "danger" });
          });
      });
    },
    [onRefresh]
  );

  const onStartNewService = useCallback((svcDefId) => {
    setServiceDefForExecution(svcDefId?.replace?.(KEY_PREFIXES.SVCDEF, ""));
  }, []);

  const onNewServiceOk = useCallback((newServiceExecution) => {
    setIsLoading(true);
    // Post the given service execution object
    new ServiceExecutionsDataService()
      .create(newServiceExecution)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.error) {
          alert(`New Service creation failed: ${res.data.reason}`, { okText: "Ok", okButtonStyle: "danger" });
        } else {
          alert(`New Service is successfully started. (${res?.data?.id})`, { okText: "Ok", okButtonStyle: "primary" });
        }
      })
      .catch((e) => {
        console.error(e);
        alert(`New Service creation failed.`, { okText: "Ok", okButtonStyle: "danger" });
      })
      .finally(() => {
        setIsLoading(false);
      });
    setServiceDefForExecution(null);
  }, []);

  return (
    <div className="container flex-column m-0 p-0 h-100">
      {editedServiceDef && (
        <ServiceDefinitionEditModal
          modalVisible={true}
          onCancel={() => setEditedServiceDef(null)}
          onOk={onModalOk}
          editedServiceDef={editedServiceDef}
        />
      )}
      {serviceDefForExecution && (
        <NewServiceExecutionModal
          svcDefId={serviceDefForExecution}
          onCancel={() => setServiceDefForExecution(null)}
          onOk={onNewServiceOk}
        />
      )}
      <div className="container-fluid d-flex flex-row align-items-center mb-2 justify-content-between">
        <div className="h2 m-0">Service Definitions</div>
        <BsFileCode className="h2 m-0 me-2" />
      </div>
      <div className="d-flex flex-column m-3 border bg-light p-4" style={{ borderRadius: "1rem", minHeight: "15rem" }}>
        <PagedTable
          numbers
          isLoading={isLoading}
          data={serviceDefinitions}
          itemsPerPage={ITEMS_PER_PAGE}
          columns={columns}
          onCreate={onCreateNewServiceDef}
          extra={{
            onEditServiceDef: onEditServiceDef,
            onDuplicate: (sk) => onEditServiceDef(sk, true),
            onStartNewService: onStartNewService,
            onDeleteService: onDeleteService,
          }}
          initialSortCriteria={{ key: "createdAt", ascending: false }}
          filterableColumns={["name", "description", "type"]}
        />
      </div>
    </div>
  );
};
export default ServiceDefinitions;
