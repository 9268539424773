import React, { useCallback, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { MdRefresh, MdAddCircle } from "react-icons/md";
import { TiArrowSortedUp as UpIcon, TiArrowSortedDown as DownIcon, TiArrowUnsorted as SortIcon } from "react-icons/ti";
import Pagination from "rc-pagination";
import "./PagedTable.css";

const filterItems = (dataItems, filterableColumns, filterText) => {
  if (!filterText || !dataItems) return dataItems;

  // Search will be case insensitive
  const ft = filterText.toLowerCase();

  return dataItems.filter((d) => {
    return filterableColumns.some((k) => {
      if (d.hasOwnProperty(k)) {
        return `${d[k] ?? ""}`.toLowerCase().includes(ft);
      } else {
        // No such property
        return false;
      }
    });
  });
};

const DEFAULT_ITEMS_PER_PAGE = 10;
const PagedTable = (props) => {
  const { isLoading, data = [], onRefresh, numbers, columns, initialSortCriteria, filterableColumns = [], onCreate } = props;
  const itemsPerPage = props?.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE;
  const [filterText, setFilterText] = useState(null);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [sortCriteria, setSortCriteria] = useState(initialSortCriteria);
  const tableRef = useRef(null);

  const filteredData = filterItems(data, filterableColumns, filterText) ?? [];
  filteredData &&
    sortCriteria &&
    filteredData.sort((a, b) => {
      const comparisonResult = a[sortCriteria.key]?.localeCompare?.(b[sortCriteria.key], undefined, { sensitivity: "base" });
      // Return the inverse of comparison based on sort criteria
      return sortCriteria.ascending ? comparisonResult : comparisonResult * -1;
    });

  const dataForCurrentPage = filteredData?.slice(currentPageNo * itemsPerPage, (currentPageNo + 1) * itemsPerPage);

  const onPageChange = useCallback((pageNo) => {
    setCurrentPageNo(pageNo - 1);
  }, []);

  const onChangeSortCriteria = useCallback((newSortKey) => {
    setSortCriteria((oldSc) => {
      let newSc = {};
      if (oldSc?.key === newSortKey) {
        // Already sorting with this key, just toggle direction
        newSc = { ...oldSc, ascending: oldSc.ascending === true ? false : true };
      } else {
        // A new sort criteria is given
        newSc = { key: newSortKey, ascending: false };
      }
      return newSc;
    });
  }, []);

  return (
    <div className="container-fluid d-flex flex-column overflow-hidden">
      <div className="d-flex flex-row align-items-center my-1 w-100" style={{ userSelect: "none" }}>
        {onCreate && (
          <>
            <Button onClick={onCreate} variant="secondary" size="sm" className="d-flex p-0 px-2 align-items-center">
              <MdAddCircle size="1em" />
              <div className="ms-1">Create</div>
            </Button>
            <div className="ms-auto"></div>
          </>
        )}
        <div className="d-flex flex-row me-2">
          <input
            type="text"
            placeholder="Filter"
            className="p-0 px-1 border border-secondary"
            onChange={(e) => setFilterText(e.target.value)}
            value={filterText ?? ""}
            style={{ fontSize: "0.875rem", borderRadius: "0.2rem", width: "10em" }}
          />
        </div>
        {!onCreate && <div className="ms-auto"></div>}
        <Pagination
          locale="en_US"
          total={filteredData.length}
          defaultPageSize={itemsPerPage}
          onChange={onPageChange}
          current={currentPageNo + 1}
        />
        {onRefresh && (
          <Button onClick={onRefresh} disabled={onRefresh === null} variant="outline-dark" size="sm" className="p-0 px-2">
            <MdRefresh size="1em" className={isLoading ? "iconspin" : ""} />
          </Button>
        )}
      </div>
      <div className="d-flex w-100 overflow-auto">
        <div
          className={isLoading ? "loadindicator" : "hidden"}
          style={{ minHeight: filteredData ? tableRef?.current?.offsetHeight : "25%", width: tableRef?.current?.offsetWidth }}
        >
          <Spinner animation="border" variant="secondary" style={{ width: "5rem", height: "5rem" }} />
        </div>
        <table className={`table table-sm table-bordered table-hover ${isLoading ? "dimmed" : ""}`} ref={tableRef}>
          <thead className="bg-dark text-light small">
            <tr>
              {numbers && <th>#</th>}
              {columns?.map((c, i) => {
                return (
                  // Make the table head clickable if the column is given as sortable
                  <th
                    key={`th${i}`}
                    onClick={c.sortable ? () => onChangeSortCriteria?.(c.key) : null}
                    style={{ cursor: c.sortable ? "pointer" : "", userSelect: "none" }}
                  >
                    <div className="d-flex flex-row align-items-center">
                      {c.text}
                      {c.sortable &&
                        (sortCriteria?.key === c.key ? (
                          sortCriteria.ascending === false ? (
                            <DownIcon className="ms-1" />
                          ) : (
                            <UpIcon className="ms-1" />
                          )
                        ) : (
                          <SortIcon className="ms-1" />
                        ))}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {dataForCurrentPage.map((d, i) => {
              return (
                <tr key={`tr${i}`} className="small">
                  {numbers && <td>{`${i + 1 + currentPageNo * itemsPerPage}`}</td>}
                  {columns?.map((c, n) => {
                    const modification = c.modify || ((s) => (s ? `${s}` : "---"));
                    return <td key={`td${n}`}>{modification(d[c.key], d.pk, d.sk, props?.extra)}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PagedTable;
