import { useCallback, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Editor from "@monaco-editor/react";
import { TemplatesDataService } from "./services/tasks.service";
import "./ModalStyles.css";
import { KEY_PREFIXES } from "./constants";

const styles = {
  dialogButtons: {
    width: "6em",
  },
};

const ScenarioDefinitionEditModal = (props) => {
  const nameRef = useRef(null);
  const descRef = useRef(null);
  const scenarioTypeRef = useRef(null);
  const templateRef = useRef(null);
  const [editedScenarioDef, setEditedServiceDef] = useState(props?.editedScenarioDef);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [templates, setTemplates] = useState({});

  const loadTemplates = useCallback(() => {
    setIsLoading(true);
    let st = [];
    let readTemplates = {};

    new TemplatesDataService()
      .fetchAll(KEY_PREFIXES.SCNDEF.replace(/#/g, ""))
      .then((res) => {
        res?.forEach?.((d) => {
          // Replace the # signs in the sortkey to get template key
          const templateKey = d.sk.replace(new RegExp(KEY_PREFIXES.SCNDEF, "g"), "");
          if (d.enabled) {
            st.push(templateKey);
            readTemplates[templateKey] = d.template;
          }
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
        setServiceTypes([...st]);
        setTemplates(readTemplates);
        setEditedServiceDef((oldSd) => {
          if (!oldSd.type) {
            oldSd.type = st[0];
          }
          if (oldSd?.pk === "-1" && oldSd?.template === "") {
            // A new service def. put in default template
            oldSd.template = readTemplates[oldSd.type];
          }
          return { ...oldSd };
        });
      });
  }, []);

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  const collectInfoFromRefs = useCallback(() => {
    return {
      name: nameRef?.current?.value,
      description: descRef?.current?.value,
      type: scenarioTypeRef?.current?.value,
      template: templateRef?.current?.getValue(),
    };
  }, [nameRef, descRef, scenarioTypeRef, templateRef]);

  const onCancel = () => {
    props?.onCancel?.();
  };

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === true) {
        props?.onOk?.(collectInfoFromRefs());
      }
      setValidated(true);
    },
    [props, collectInfoFromRefs]
  );

  const onTypeChanged = useCallback(
    (e) => {
      setEditedServiceDef((oldSd) => {
        oldSd.type = e.target.value;
        // Only change the template of the edited scenario def if it's newly created
        if (oldSd.pk === "-1") {
          templateRef?.current?.setValue(templates[e.target.value]);
          oldSd.template = templates[e.target.value];
        }
        return { ...oldSd };
      });
    },
    [templates, templateRef]
  );

  return (
    <Modal show={props?.modalVisible} onHide={onCancel} size="xl" centered animation={true}>
      <Form noValidate validated={validated} onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex flex-row">{editedScenarioDef?.pk === "-1" && "New "}Scenario Definition</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "70vh",
            overflowY: "auto",
            opacity: isLoading ? "0.4" : "",
          }}
        >
          <Form.Group className="formrow">
            <Form.Label className="formlabel">Name</Form.Label>
            <Form.Control
              ref={nameRef}
              type="text"
              placeholder="Name"
              defaultValue={editedScenarioDef?.name}
              required
              disabled={isLoading}
            />
            <Form.Control.Feedback type="invalid">Please enter a name.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="formrow">
            <Form.Label className="formlabel">Description</Form.Label>
            <Form.Control
              ref={descRef}
              type="text"
              placeholder="Description"
              defaultValue={editedScenarioDef?.description}
              required
              disabled={isLoading}
            />
            <Form.Control.Feedback type="invalid">Please enter a description.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="formrow">
            <Form.Label className="formlabel">Type</Form.Label>
            <Form.Select
              aria-label="Default select example"
              defaultValue={serviceTypes[0]}
              value={editedScenarioDef?.type}
              ref={scenarioTypeRef}
              disabled={isLoading}
              onChange={onTypeChanged}
            >
              {serviceTypes.map((s) => {
                return (
                  <option value={s} key={`key${s}`}>
                    {s}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="formrow align-items-start">
            <Form.Label className="formlabel">Template</Form.Label>
            <div className="d-flex p-0 col mb-3" style={{ height: "20em" }}>
              {!isLoading && (
                <Editor
                  disabled={isLoading}
                  className="form-control form-control-sm p-0"
                  onMount={(ed) => {
                    templateRef.current = ed;
                  }}
                  defaultValue={editedScenarioDef?.template}
                  options={{
                    scrollBeyondLastLine: false,
                    minimap: { enabled: false },
                    readOnly: isLoading,
                  }}
                />
              )}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCancel} variant="secondary" style={styles.dialogButtons}>
            Close
          </Button>
          <Button type="submit" variant="primary" style={styles.dialogButtons}>
            Ok
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ScenarioDefinitionEditModal;
