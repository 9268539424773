import axios from "axios";
import AuthService from "./auth.service";

class CommonDataService {
  constructor(apiURLExtension) {
    // Set apiURLExtension to extend this class
    this.apiURLExtension = apiURLExtension;
    this.baseAPIURL = process?.env?.NODE_ENV === "development" ? "/v1" : `https://api-quadsim.quadified.com`;
    this.fullAPIURL = this.baseAPIURL + "/" + apiURLExtension;
  }

  // sends a GET request but also attaches the auth header (if available)
  async getRequest(url, { headers, ...config } = {}) {
    const jwt = await AuthService.authHeader();
    const reqConfig = { headers: { ...jwt, ...headers }, ...config };
    return axios.get(url, reqConfig);
  }

  // sends a PUT request but also attaches the auth header (if available)
  async putRequest(url, data, { headers, ...config } = {}) {
    const jwt = await AuthService.authHeader();
    const reqConfig = { headers: { ...jwt, ...headers }, ...config };
    return axios.put(url, data, reqConfig);
  }

  // sends a POST request but also attaches the auth header (if available)
  async postRequest(url, data, { headers, ...config } = {}) {
    const jwt = await AuthService.authHeader();
    const reqConfig = { headers: { ...jwt, ...headers }, ...config };
    return axios.post(url, data, reqConfig);
  }

  // sends a PATCH request but also attaches the auth header (if available)
  async patchRequest(url, data, { headers, ...config } = {}) {
    const jwt = await AuthService.authHeader();
    const reqConfig = { headers: { ...jwt, ...headers }, ...config };
    return axios.patch(url, data, reqConfig);
  }

  // sends a DELETE request but also attaches the auth header (if available)
  async deleteRequest(url, { headers, ...config } = {}) {
    const jwt = await AuthService.authHeader();
    const reqConfig = { headers: { ...jwt, ...headers }, ...config };
    return axios.delete(url, reqConfig);
  }

  marshallKey(key) {
    return Buffer.from(JSON.stringify(key), "utf-8").toString("base64");
  }

  fetchItems(noOfItems = null, lastEvaluatedKey = null, projection = null) {
    // Send the last evaluated key as stringified JSON object (base64 encoded)
    let pathQuery = [];

    noOfItems && pathQuery.push(`items=${noOfItems}`);
    lastEvaluatedKey && pathQuery.push(`lastkey=${this.marshallKey(lastEvaluatedKey)}`);
    projection && pathQuery.push(`projection=${projection}`);

    return this.getRequest(`${this.fullAPIURL}${pathQuery.length > 0 ? `?${pathQuery.join("&")}` : ""}`);
  }

  async fetchAll() {
    // Do the first fetch
    let data = [];
    try {
      let res = await this.fetchItems(null, null, "short");
      data = [...data, ...res?.data?.["Items"]];
      while (res?.data?.LastEvaluatedKey) {
        // Now call remaining fetchs
        res = await this.fetchItems(null, res.data.LastEvaluatedKey, "short");
        if (res?.data?.["Items"]) {
          data = [...data, ...res?.data?.["Items"]];
        }
      }
    } catch (e) {
      console.error(e);
    }
    return data;
  }

  getURLForId = (id) => {
    return `${this.fullAPIURL}/${id}`;
  };

  fetch(id) {
    return this.getRequest(`${this.getURLForId(id)}`);
  }

  create(entityDetails) {
    return this.postRequest(`${this.fullAPIURL}`, entityDetails);
  }

  update(id, entityDetails) {
    return this.patchRequest(this.getURLForId(id), entityDetails);
  }

  delete(id) {
    return this.deleteRequest(this.getURLForId(id));
  }
}

class ServiceDefinitionsDataService extends CommonDataService {
  constructor() {
    super("servicedefinitions");
  }
}

class TemplatesDataService extends CommonDataService {
  constructor() {
    super("templates");
  }

  async fetchAll(group = null) {
    // Do the first fetch
    let data = [];
    // abusing the "projection" parameter here to sneak in the "group" query parameter
    const queryValue = `short${group ? `&group=${group}` : ""}`;
    try {
      let res = await this.fetchItems(null, null, queryValue);
      data = [...data, ...res?.data?.["Items"]];
      while (res?.data?.LastEvaluatedKey) {
        // Now call remaining fetchs
        res = await this.fetchItems(null, res.data.LastEvaluatedKey, queryValue);
        if (res?.data?.["Items"]) {
          data = [...data, ...res?.data?.["Items"]];
        }
      }
    } catch (e) {
      console.error(e);
    }
    return data;
  }
}

class ScenarioDefinitionsDataService extends CommonDataService {
  constructor() {
    super("scenariodefinitions");
  }
}

class ServiceExecutionsDataService extends CommonDataService {
  constructor() {
    super("serviceexecutions");
  }

  operationOnMultiple(operation) {
    // send the multiple stop operations as a patch request
    return this.patchRequest(`${this.fullAPIURL}`, operation);
  }

  async fetchAll(type = null, state = null) {
    let data = [];
    // abusing the "projection" parameter here to sneak in the "type" and "state" query parameters
    let queryItems = ["short"];
    if (type) queryItems.push(`type=${type}`);
    if (state) queryItems.push(`state=${state}`);
    const queryValue = queryItems.join("&");
    try {
      let res = await this.fetchItems(null, null, queryValue);
      data = [...data, ...res?.data?.["Items"]];
      while (res?.data?.LastEvaluatedKey) {
        // Now call remaining fetchs
        res = await this.fetchItems(null, res.data.LastEvaluatedKey, queryValue);
        if (res?.data?.["Items"]) {
          data = [...data, ...res?.data?.["Items"]];
        }
      }
    } catch (e) {
      console.error(e);
    }
    return data;
  }

  async fetchScenarioExecution(svcId, scnId) {
    return this.getRequest(`${this.fullAPIURL}/${svcId}/scenarioexecutions/${scnId}`);
  }

  async createScenarioExecution(svcId, scenarioExecutionParams) {
    return this.postRequest(`${this.fullAPIURL}/${svcId}/scenarioexecutions`, { ...scenarioExecutionParams });
  }

  async stopScenarioExecution(svcId, scnId) {
    return this.deleteRequest(`${this.fullAPIURL}/${svcId}/scenarioexecutions/${scnId}`);
  }
}

export {
  CommonDataService,
  TemplatesDataService,
  ServiceDefinitionsDataService,
  ScenarioDefinitionsDataService,
  ServiceExecutionsDataService,
};
