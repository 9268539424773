import React, { useState } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import Container from "react-bootstrap/Container";
import "./Sidebar.css";

const Sidebar = (props) => {
  const [open, setOpen] = useState(true);

  return (
    <div
      className={`d-flex flex-column sidebar-${open ? "open" : "closed"}`}
      style={{ cursor: !open ? "pointer" : "" }}
      onClick={
        !open
          ? () => {
              setOpen((o) => !o);
            }
          : null
      }
    >
      <div className="d-flex flex-row justify-content-end m-2">
        <div
          style={{ cursor: open ? "pointer" : "" }}
          onClick={
            open
              ? () => {
                  setOpen((o) => !o);
                }
              : null
          }
        >
          {open ? <FiX /> : <FiMenu />}
        </div>
      </div>
      <hr className="my-1" />
      <Container className="p-0 px-1" style={{ display: open ? "" : "none" }}>{props.children}</Container>
    </div>
  );
};

export default Sidebar;
