const KEY_PREFIXES = Object.freeze({
  SVCDEF: "#SVCDEF#",
  SCNDEF: "#SCNDEF#",
  SVCEXEC: "#SVCEXEC#",
  SCNEXEC: "#SCNEXEC#",
});

const EXECUTION_STATES = Object.freeze({
  PENDING: "PENDING",
  IDLE: "IDLE",
  CONNECTED: "CONNECTED",
  RUNNING: "RUNNING",
  COMPLETED: "COMPLETED",
  STOPPED: "STOPPED",
  FAILED: "FAILED",
});

const ACTIVE_EXECUTION_STATES = new Set([
  EXECUTION_STATES.PENDING,
  EXECUTION_STATES.IDLE,
  EXECUTION_STATES.CONNECTED,
  EXECUTION_STATES.RUNNING,
]);

const EXECUTIONSTATECOLORMAPS = Object.freeze({
  [EXECUTION_STATES.PENDING]: "secondary",
  [EXECUTION_STATES.IDLE]: "secondary",
  [EXECUTION_STATES.CONNECTED]: "info",
  [EXECUTION_STATES.RUNNING]: "primary",
  [EXECUTION_STATES.COMPLETED]: "success",
  [EXECUTION_STATES.FAILED]: "danger",
  [EXECUTION_STATES.STOPPED]: "dark",
});

const AUTO_REFRESH_PERIOD_MS = 5000;

export { KEY_PREFIXES, EXECUTION_STATES, EXECUTIONSTATECOLORMAPS, AUTO_REFRESH_PERIOD_MS, ACTIVE_EXECUTION_STATES };
